import type { RequestOptions } from './types'
import { useRequestError } from './useRequestError'
import { type API } from '~/services/sdk/api'

export type RequestWrapperCallback<T extends any[] = any[]> = (
  api: API,
  ...args: T
) => Promise<unknown>

export type RequestWrapperAction<T extends any[]> = (
  ...args: T
) => Promise<unknown>

export type RequestWrapper<T extends any[]> = {
  error: Ref<string>
  reset: () => void
  processing: Ref<boolean>
  action: RequestWrapperAction<T>
}

export const useRequestWrapper = <T extends any[]>(
  callback: RequestWrapperCallback<T>,
  options?: RequestOptions,
): RequestWrapper<T> => {
  const api = useAPI(options?.withoutAuthorization)
  const toast = useToast()

  const error = useRequestError({
    fields: options?.fields,
    toast: options?.toast?.error,
  })

  const processing = ref<boolean>(false)

  return {
    processing,
    error: error.value,
    reset: error.clear,
    action: async (...args) => {
      try {
        error.clear()

        processing.value = true

        const result = await callback(api, ...args)

        if (typeof result === 'string') toast.success(result)
      } catch (e) {
        console.dir(e)
        if (e instanceof Response) {
          // const isJson = e.headers
          //   ?.get('content-type')
          //   ?.includes('application/json')

          // const data = isJson ? await e.json() : ''

          const data = await e.json()

          error.set(data)
        } else {
          error.set(e)
        }
      } finally {
        processing.value = false
      }
    },
  }
}
