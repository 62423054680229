import type {
  RequestError,
  RequestErrorFields,
  RequestErrorOptions,
} from './types'
import { useRequestErrorText } from './useRequestErrorText'
import { ApiError } from '~/services/sdk/api'

export const isValidationError = <T extends string>(
  error: unknown,
  fields?: RequestErrorFields,
): error is Omit<ApiError, 'body'> & {
  body: { errors: Record<T, string[]> }
} => {
  const unrefFields = unref(fields)

  return (
    !!unrefFields &&
    error instanceof ApiError &&
    isTrueObject<{ errors: T }>(error.body) &&
    isTrueObject<T>(error.body.errors) &&
    Object.keys(error.body.errors).some((key) => key in unrefFields)
  )
}

export const useRequestError = (
  options?: RequestErrorOptions,
): RequestError => {
  const { t } = useI18n()
  const toast = useToast()
  const value = ref<string>('')

  const resolveErrorText = useRequestErrorText()

  return {
    value,
    set: (error) => {
      if (
        error instanceof ApiError &&
        isValidationError(error, options?.fields)
      ) {
        const unrefFields = unref(options?.fields)
        Object.entries(error.body.errors).forEach(([key, value]) => {
          const field = unrefFields?.[key]
          if (field) field.data.validationError = t(`apiErrors.${value}`)
        })
      } else {
        value.value = resolveErrorText(error)
      }

      if (options?.toast) toast.danger(value.value)
    },
    clear: () => {
      value.value = ''
    },
  }
}
